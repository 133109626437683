export const locationButtonsPlugin = () => {
    const locationButtonsMain = document.querySelector('.location-buttons-plugin');

    if(locationButtonsMain) {
        const locationButtons = locationButtonsMain.querySelectorAll('.buttons-container a');
    
        locationButtons.forEach(elem => {
            if(elem?.dataset?.color) {
                elem.style.background = `linear-gradient(0deg, ${elem.dataset.color} 0px, ${elem.dataset.color} 8px, rgb(255, 255, 255) 8px, rgb(255, 255, 255) 100%) rgb(255, 255, 255)`

                elem.addEventListener('mouseover', () =>{
                    elem.style.background = elem.dataset.color;
                });
                elem.addEventListener('mouseout', () =>{
                    elem.style.background = `linear-gradient(0deg, ${elem.dataset.color} 0px, ${elem.dataset.color} 8px, rgb(255, 255, 255) 8px, rgb(255, 255, 255) 100%) rgb(255, 255, 255)`
                });
            };
        });
    };
};
